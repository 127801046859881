import * as React from "react";
import * as styles from "./sign-in-page.module.css";

import { FunctionComponent, useCallback } from "react";

import Layout from '../components/Base/Layout';
import LoginForm from "../components/Login/LoginForm";
import { SEO } from "../components/Base/Seo";
import UnAuthContent from "../components/Login/UnAuthContent";

const SignInPage: FunctionComponent = () => {

  return (
    <Layout>
      <UnAuthContent>
        <LoginForm></LoginForm>
      </UnAuthContent>
    </Layout>
  );
};

export default SignInPage;

export const Head = () => (
  <SEO title="CDN Health | Sign in" description="Sign in page" />
)
